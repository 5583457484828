

td.blue
{
	background-color: lightblue;
}


.span
{
	margin-left: 25px;
}


.main_navigation a
{
	color: #6c757d;
}


.marginTop
{
	margin-top: 15px;
}


.red
{
	color: red;
}
